
  import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
  import { Action, Getter } from "vuex-class";
  import { WorkStatus } from "@/store/modules/activity.store";
  import { HasEnumMethods } from "@/mixins/has-enum-methods";
  import { Debounce } from "@/utils/vue-helpers";
  import { HasMyWorkActions } from "@/mixins/activities/has-my-work-actions";

  export interface MyWorkFilter {
    users: number[];
    taskGroup: string[];
    status: WorkStatus[];
  }

  @Component({
    components: {
      GeneralHeaderBar: () => import("@/components/general/header-bar.vue"),
      FilterSelect: () => import("@/components/filter/select.vue"),
      FilterSearch: () => import("@/components/filter/search.vue"),
      UserSelect: () => import("@/components/forms/user-select.vue"),
      UserImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
      UserImage: () => import("@/components/general/user-image.vue"),
    },
  })
  export default class MyWorkPartFilter extends Mixins(HasEnumMethods, HasMyWorkActions) {
    @Getter("account/all") accounts!: Account[];

    @Action("my-work/searchTasks") searchTasks!: (payload: MyWorkTaskIndexPayload) => MyWorkTaskSearchResult[];

    @Prop() filter!: MyWorkFilter;
    @Prop() search!: string;

    statusOptions = this.optionsFromEnum(WorkStatus, "property.work_status");

    taskGroupOptions = [
      {
        value: "Wettelijke bijlagen",
        label: "Wettelijke bijlagen",
      },
      {
        value: "Keuringen",
        label: "Keuringen",
      },
    ];

    suggestedTasks: MyWorkTaskSearchResult[] = [];

    loadingSearch = false;

    localSearch = "";

    @Emit("search-changed")
    handleInput() {
      return this.localSearch;
    }

    @Debounce(500)
    async handleSearchSuggestions(query: string) {
      this.suggestedTasks = [];

      if (!query || query.length <= 0) {
        this.handleSearch("");
        return;
      }

      this.loadingSearch = true;

      this.suggestedTasks = await this.searchTasks({ q: this.createMyWorkTaskQuery(this.filter!.users[0], null, this.filter!.taskGroup, query) });

      this.loadingSearch = false;
    }

    handleSearch(query: string) {
      this.localSearch = query;

      this.handleInput();
    }

    handleSelected(result: MyWorkTaskSearchResult) {
      this.localSearch = result.title;

      this.handleInput();
    }

    @Watch("search", { immediate: true })
    searchChanged(newValue: string) {
      this.localSearch = newValue;
    }
  }
